import * as React from 'react';
import { useEffect, useState } from 'react';
import AuthLayout from '../../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../../bidder/components/Panels/BidderPanel';
import TableHeader from '../../../../bidder/components/Table/TableHeader';
import { Box, Typography } from '@mui/material';
import Table from '../../../../bidder/components/Table/Table';
import TableBody from '../../../../bidder/components/Table/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { formatDateTime } from '../../../../services/date';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import TableRow from '../../../../bidder/components/Table/TableRow';
import TableHeaderCell from '../../../../bidder/components/Table/TableHeaderCell';
import { getSortParamFromFilter } from '../../../../bidder/services/table';
import {
  getFailedJobs,
  retryFailedJob
} from '../../../../bidder/services/dashboard/additional/failed-jobs';
import { cutString } from '../../../../bidder/helpers/string-helper';
import { useDispatch } from 'react-redux';
import { ReactComponent as RetryIcon } from '../../../../bidder/assets/icons/retry.svg';

export default function List() {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [filter, setFilter] = useState({
    search: '',
    columns: [
      {
        key: 'id',
        name: '#',
        class: 'align-left-column',
        sort: 'asc',
        sortable: true,
        filterable: false
      },
      {
        key: 'uuid',
        name: 'UUID',
        sortable: true,
        filterable: false
      },
      {
        key: 'exception',
        name: 'Exception',
        sortable: false,
        filterable: false
      },
      {
        key: 'failed_at',
        name: 'Failed at',
        sortable: true,
        filterable: false
      },
      {
        key: 'actions',
        name: 'Actions',
        sortable: false,
        filterable: false
      }
    ]
  });

  useEffect(() => {
    getFailedJobs({
      params: {
        filter: {
          search
        },
        page: page + 1,
        per_page: rowsPerPage,
        sort: getSortParamFromFilter(filter)
      }
    }).then((response) => {
      setData(response.data);
    });
  }, [search, page, rowsPerPage, filter]);

  const onSort = (column, value) => {
    let newFilter = filter;
    newFilter['columns'].map((item) => {
      item.sort = null;

      return item;
    });
    let index = newFilter['columns'].findIndex((item) => item.key === column);
    newFilter['columns'][index].sort = value;
    setFilter({ ...newFilter });
  };

  const renderHeaderContentCallback = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '45px',
            padding: '0 10px'
          }}>
          <Box sx={{ width: '520px' }}>
            <SearchInput
              value={search}
              onChange={(event) => {
                setPage(0);
                setSearch(event.target.value);
              }}
            />
          </Box>
        </Box>
      </>
    );
  };

  const handleRetry = (id) => {
    if (confirm('Are you sure you want to retry this job?')) {
      retryFailedJob(id).then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Job has been retried successfully',
          toast_type: 'success'
        });

        getFailedJobs({
          params: {
            filter: {
              search
            },
            page: page + 1,
            per_page: rowsPerPage,
            sort: getSortParamFromFilter(filter)
          }
        }).then((response) => {
          setData(response.data);
        });
      });
    }
  };

  return (
    <>
      <AuthLayout title="Failed Jobs" renderHeaderContentCallback={renderHeaderContentCallback}>
        <BidderPanel>
          <Table>
            <TableHeader>
              <TableRow>
                {filter.columns.map((column, index) => (
                  <TableHeaderCell key={index} filter={filter} column={column} onSort={onSort} />
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {data !== null &&
                data.data.map((item) => {
                  return (
                    <tr key={item.id} className={'cursor-pointer'}>
                      <td className={'align-left-column'}>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {item.id}
                        </Typography>
                      </td>
                      <td className={'align-left-column'}>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {item.uuid}
                        </Typography>
                      </td>
                      <td className={'align-left-column'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          {cutString(item.exception, 100)}
                        </Typography>
                      </td>
                      <td>{formatDateTime(item.failed_at)}</td>
                      <td>
                        <RetryIcon
                          className={'icon-pointer'}
                          onClick={() => handleRetry(item.id)}></RetryIcon>
                      </td>
                    </tr>
                  );
                })}
            </TableBody>
          </Table>
          {data !== null && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.meta.total}
              rowsPerPage={data.meta.per_page}
              page={data.meta.current_page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </BidderPanel>
      </AuthLayout>
    </>
  );
}
